import React, { useState } from 'react'
import CardTireModel, {
  ICardTireModelo,
} from '../../../common/components/CardTireModel/CardTireModel'
import { Neumaticos } from '../../../common/components/FichasNeumaticos/types'
import { t } from '../../../common/i18n'
import ButtonGrid from '../ListFilter/ButtonGrid'
import FilterButton from '../ListFilter/FilterButton'
import styles from './index.module.scss'
import Size from '../../../common/utils/media-queries-setup'
import { getTiposVehiculo } from '../../../common/components/LandingMarca/utils'
import _ from 'lodash'

type SeccionNeumaticoProps = {
  marca: string
  logoMarca: string
  neumaticos: Neumaticos[]
}

const SeccionNeumaticoPorTipoVehiculo = ({
  marca,
  logoMarca,
  neumaticos,
  tipovehiculo,
}) => {
  const allSeason: ICardTireModelo[] = neumaticos.filter(
    (neumatico) =>
      neumatico.tipovehiculo === tipovehiculo && neumatico.allseason
  )
  const invierno: ICardTireModelo[] = neumaticos.filter(
    (neumatico) => neumatico.tipovehiculo === tipovehiculo && neumatico.invierno
  )
  const verano: ICardTireModelo[] = neumaticos.filter(
    (neumatico) => neumatico.tipovehiculo === tipovehiculo && neumatico.verano
  )

  return (
    <div key={tipovehiculo} className="grupo-tipo">
      {verano.length > 0 && (
        <CardTireModel
          marca={marca}
          logoMarca={logoMarca}
          neumaticos={_.uniqBy(verano, 'slug')}
          title={t('ficha-marca.'.concat(tipovehiculo.toLowerCase())).concat(
            ' ',
            t('ficha-marca.verano')
          )}
        />
      )}
      {allSeason.length > 0 && (
        <CardTireModel
          marca={marca}
          logoMarca={logoMarca}
          neumaticos={_.uniqBy(allSeason, 'slug')}
          title={t('ficha-marca.'.concat(tipovehiculo.toLowerCase())).concat(
            ' ',
            t('ficha-marca.todo-el-año')
          )}
        />
      )}
      {invierno.length > 0 && (
        <CardTireModel
          marca={marca}
          logoMarca={logoMarca}
          neumaticos={_.uniqBy(invierno, 'slug')}
          title={t('ficha-marca.'.concat(tipovehiculo.toLowerCase())).concat(
            ' ',
            t('ficha-marca.invierno')
          )}
        />
      )}
    </div>
  )
}

const SeccionNeumatico: React.FC<SeccionNeumaticoProps> = ({
  marca,
  logoMarca,
  neumaticos,
}) => {
  const [activeFilter, setActiveFilter] = useState<string>('todos')
  const tiposVehiculo = getTiposVehiculo(neumaticos)

  const handleFilterClick = (filter: string) => {
    setActiveFilter(filter === activeFilter ? 'todos' : filter)
  }
  const isMobile = Size.useMedia({ mobile: true, tablet: false })

  const allSeason: ICardTireModelo[] = neumaticos.filter(
    (neumatico) => neumatico.allseason === 1
  )
  const invierno: ICardTireModelo[] = neumaticos.filter(
    (neumatico) => neumatico.invierno === 1
  )
  const verano: ICardTireModelo[] = neumaticos.filter(
    (neumatico) => neumatico.verano === 1
  )
  const totalNeumaticos = allSeason.length + invierno.length + verano.length
  const filterButtonsData = [
    {
      key: 'todos',
      label: t('neumaticos_filtros.todos'),
      total: totalNeumaticos,
    },
    {
      key: 'verano',
      label: t('neumaticos_filtros.verano'),
      total: verano.length,
    },
    {
      key: 'invierno',
      label: t('neumaticos_filtros.invierno'),
      total: invierno.length,
    },
    {
      key: 'allseason',
      label: t('neumaticos_filtros.allseason'),
      total: allSeason.length,
    },
  ]
  const neumaticosData = (filter): ICardTireModelo[] => {
    switch (filter) {
      case 'verano':
        return verano
      case 'invierno':
        return invierno
      case 'allseason':
        return allSeason
      default:
        return neumaticos as unknown[] as ICardTireModelo[]
    }
  }
  const activeNeumaticos = neumaticosData(activeFilter)

  return (
    <>
      <div className={styles.filter_group}>
        <ButtonGrid>
          {filterButtonsData.map(
            (filter) =>
              filter.total > 0 && (
                <FilterButton
                  key={filter.key}
                  label={filter.label}
                  total={filter.total}
                  isActive={activeFilter === filter.key}
                  onClick={() => handleFilterClick(filter.key)}
                />
              )
          )}
        </ButtonGrid>
      </div>

      {activeFilter !== 'todos' && activeNeumaticos.length > 0 && (
        <CardTireModel
          marca={marca}
          logoMarca={logoMarca}
          neumaticos={activeNeumaticos}
        />
      )}
      {activeFilter === 'todos' &&
        tiposVehiculo.map((tipovehiculo) => (
          <SeccionNeumaticoPorTipoVehiculo
            key={tipovehiculo}
            marca={marca}
            logoMarca={logoMarca}
            neumaticos={neumaticos}
            tipovehiculo={tipovehiculo}></SeccionNeumaticoPorTipoVehiculo>
        ))}
    </>
  )
}
export default SeccionNeumatico
